<template>
  <div class="text-center py-12 bg-bm-primary text-white"
       :style="{ backgroundImage: 'url(\'' + require('@/assets/images/bg.png') + '\')' }"
       style="background-repeat: no-repeat; background-size: cover">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'InfoBox',
  components: {},
  setup() {
    return {}
  }
}
</script>

<style scoped>
</style>
