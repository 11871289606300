<template>
  <div class="flex justify-center">
    <div class="fmu-container w-full py-16">
      <div class="grid md:grid-cols-2 grid-cols-1">
        <div class="px-5 md:px-0 md:pl-10 xl:pl-0">
          <img :src="require('@/assets/images/productinfo/productinfo-1.jpg')" alt="">
        </div>
        <div class="pl-10 pr-10 xl:pr-0 xl:pt-10">
          <h1 class="font-bold font-condensed text-black text-headline" style="line-height: 1.2">1000E FERTIGE VORLAGEN
            FÜR DEIN MARKETING</h1>
          <p class="text-subline pt-5">Endlich eine Online-Plattform für den Textil- und Schuh-Einzelhandel, mit der
            Du als Retailer Dein gesamtes Marketing „in einem Aufwasch“ schnell und effizient
            zusammenstellen kannst. Hier findest Du 1000e fertige Marketing-Vorlagen zum
            direkten Download. Alles an einem Ort.
          </p>
          <fmu-button button-type="primary" class="mt-5" @button-click="jumpToSearch">Zu den Contents</fmu-button>
        </div>
      </div>
      <div class="grid md:grid-cols-2 grid-cols-1 pt-20">
        <div class="md:hidden px-10">
          <img :src="require('@/assets/images/productinfo/productinfo-2.jpg')" alt="">
        </div>
        <div class="pl-10 pl-10 xl:pl-0 pr-10 xl:pt-16">
          <h1 class="font-bold font-condensed text-black text-headline" style="line-height: 1.2">MIT WENIGEN KLICKS
            SCHNELL ZUM WUNSCHTHEMA</h1>
          <p class="text-subline pt-5">Welche Aktion benötigst Du? Saisonstart, Sale, Rabattaktion, Modenschau,
            verkaufsoffener Sonntag, Muttertag, Weihnachten…? Über die Suchfunktion kannst Du Dein gewünschtes
            saisonales Thema ganz einfach aus unserer riesigen Datenbank auswählen.</p>
          <fmu-button button-type="primary" class="mt-5" @button-click="jumpToSearch">Zu den Contents</fmu-button>
        </div>
        <div class="hidden md:block pr-10 xl:pr-0">
          <img :src="require('@/assets/images/productinfo/productinfo-2.jpg')" alt="">
        </div>
      </div>
      <div class="grid md:grid-cols-2 grid-cols-1 pt-20">
        <div class="px-5 xl:px-0">
          <img :src="require('@/assets/images/productinfo/productinfo-3.jpg')" alt="">
        </div>
        <div class="pl-10 pr-10 xl:pr-0 xl:pt-10">
          <h1 class="font-bold font-condensed text-black text-headline" style="line-height: 1.2">BILDER, VIDEOS,
            ANIMATIONEN & TEXTE
            ZUM DOWNLOAD</h1>
          <p class="text-subline pt-5">Die Cloud liefert Dir die verschiedensten Formate: Aktionsideen, Layouts, Bilder,
            kurze Video-Clips, Animationen und Textvorlagen. Alles liegt bereits fertig für Deine Werbekanäle bereit und
            kann „flatrate“ für Deine Filialen genutzt werden.</p>
          <fmu-button button-type="primary" class="mt-5" @button-click="jumpToSearch">Zu den Contents</fmu-button>
        </div>
      </div>
      <div class="grid md:grid-cols-2 grid-cols-1 pt-20">
        <div class="md:hidden">
          <img :src="require('@/assets/images/productinfo/productinfo-4.jpg')" alt="">
        </div>
        <div class="pl-10 xl:pl-0 pr-10 xl:pt-16">
          <h1 class="font-bold font-condensed text-black text-headline" style="line-height: 1.2">FÜR DEINE SOCIAL MEDIA,
            WEBSITE, APP UND POSTKARTEN</h1>
          <p class="text-subline pt-5">Du hast Dich für eine Aktion entschieden? Prima! Nun kannst Du den Content
            einfach downloaden und all Deine Werbekanäle auf einmal mit dem gleichen Design bespielen: Social Media,
            App, Website, Webshop oder sogar fertige Postkarten für den postalischen Versand. Je nach Abo & Aktion
            findest Du eine Vorlage im passenden Format.</p>
          <fmu-button button-type="primary" class="mt-5" @button-click="jumpToSearch">Zu den Contents</fmu-button>
        </div>
        <div class="hidden md:block">
          <img :src="require('@/assets/images/productinfo/productinfo-4.jpg')" alt="">
        </div>
      </div>
      <div class="grid md:grid-cols-2 grid-cols-1 pt-20">
        <div class="mx-5 xl:mx-0">
          <img :src="require('@/assets/images/productinfo/productinfo-5.jpg')" alt="">
        </div>
        <div class="pr-10 xl:pr-0 pl-10 xl:pt-10">
          <h1 class="font-bold font-condensed text-black text-headline" style="line-height: 1.2">VIA CONTENT-HUB
            DIREKT AUSSTEUERN</h1>
          <p class="text-subline pt-5">Du willst noch schneller und effizienter sein? Dank unserem Content-Hub kannst Du
            Deine Bilder, Videos und Texte auf Wunsch sogar auch direkt auf Deiner Facebook Seite, Instagram, App
            Newsblog, Shopware Newsblog oder Woocoomerce/Wordpress Newsblog aussteuern und brauchst nichts mehr
            herunterzuladen. Kein Zwischenschritt erforderlich – schneller geht’s nicht!</p>
          <fmu-button button-type="primary" class="mt-5" @button-click="jumpToSearch">Zu den Contents</fmu-button>
        </div>
      </div>
      <div class="grid md:grid-cols-2 grid-cols-1 pt-20">
        <div class="md:hidden mx-5">
          <img :src="require('@/assets/images/productinfo/productinfo-6.jpg')" alt="">
        </div>
        <div class="pl-10 xl:pl-0 pr-10 xl:pt-20">
          <h1 class="font-bold font-condensed text-black text-headline" style="line-height: 1.2">
            KUNDEN-APP-KOMPATIBEL</h1>
          <p class="text-subline pt-5">Eine wichtige Info für unsere über 150 Textil- und Schuh-Einzelhändler aus ganz
            Deutschland, Österreich und der Schweiz, die die Kunden-App von Hutter & Unger in ihren Geschäften nutzen.
            Für Euch ist die Cloud direkt im smart.KIS integriert.</p>
          <a href="https://kunden-app.com" target="_blank">
            <fmu-button button-type="primary" class="mt-5">Zur Kunden-App</fmu-button>
          </a>
        </div>
        <div class="hidden md:block mx-5">
          <img :src="require('@/assets/images/productinfo/productinfo-6.jpg')" alt="">
        </div>
      </div>
      <div class="grid md:grid-cols-2 grid-cols-1 pt-20">
        <div class="mx-5 xl:mx-0">
          <img :src="require('@/assets/images/productinfo/productinfo-7.jpg')" alt="">
        </div>
        <div class="pr-10 xl:pr-0 pl-10 xl:pt-10">
          <h1 class="font-bold font-condensed text-black text-headline" style="line-height: 1.2">WÄHLE JETZT DEIN ABO
            AUS!</h1>
          <p class="text-subline pt-5">Wir bieten Dir absolute Kostensicherheit durch ein klares Abo-Modell. 4
            verschiedene Abos stehen Dir zur Auswahl. Der Vorteil: Egal für welches Abo Du Dich entscheidest, das
            Kosten-Nutzen-Verhältnis dürfte im Vergleich zu Deinen bisherigen Marketingbudgets spürbar positiv sein.
            Erfahre mehr über die jeweiligen Pricings, Features und Nutzungsrechte.</p>
          <fmu-button button-type="primary" class="mt-5" @button-click="jumpToPricing">Zum Pricing</fmu-button>
        </div>
      </div>
    </div>
  </div>
  <info-box>
    <p class="text-2xl md:text-headline font-bold font-condensed">MARKETING KANN JETZT JEDER... <br> MIT DER
      BASICMARKETING.CLOUD!</p>
    <fmu-button class="mt-6" @button-click="jumpToPricing">Jetzt sofort loslegen</fmu-button>
  </info-box>
</template>

<script>
export default {
  name: 'ProductInfo',
  components: {},
  emits: ['button-click'],
  setup() {
    return {}
  }
}
</script>

<script setup>
import FmuButton from "../../components/theme/button/FmuButton";
import {useRouter} from "vue-router";
import InfoBox from "../../components/infobox/InfoBox";

const router = useRouter()

const jumpToSearch = () => {
  router.push({name: 'home', hash: '#filter'})
}

const jumpToContact = () => {
  router.push({name: 'contact'})
}

const jumpToPricing = () => {
  router.push({name: 'pricing'})
}
</script>

<style scoped>
</style>
